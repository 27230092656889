<template>
<div class="study">
  <div class="user-tit">我的学习</div>
  <div class="tab-list">
    <div class="tab-list-item" :class="curTabIndex == index ? 'active' : ''" v-for="(item,index) in tabList" :key="item.id" @click="changeTab(index)">
      <p>{{ item.name }}</p>
      <div></div>
    </div>
  </div>
  <div class="study-list" v-if="dataList.length">
    <div class="study-list-item" v-for="item in dataList" :key="item.id">
      <img :src="item.category.img" width="235" height="162" />
      <p class="study-list-item-status" :class="item.study_status == 2 ? 'gray' : ''">{{ studyStatus[item.study_status] }}</p>
      <div class="study-list-item-name">{{ item.category.name }}</div>
      <div class="study-list-item-desc" v-if="item.study_status == 0 && item.exam_remain_num > 0">当前考试次数: 剩余 {{ item.exam_remain_num }} 次</div>
      <div class="study-list-item-desc" style="opacity: 0" v-else>当前考试次数</div>
      <div class="study-list-item-desc">考试截止时间: {{ item.exam_end_time }}</div>

      <div class="study-list-item-btn" @click="goDetail(item)" v-if="item.study_status == 2 || item.exam_remain_num == 0">重新激活</div>
      <div class="study-list-item-btn" @click="goStudy(item)" v-else>去学习</div>
    </div>
  </div>
  <div class="no-data" v-if="!dataList.length && !loading">
    <img src="../../assets/images/common/no-data.png" width="223" height="129" />
    <p>还没有购买认证哦~</p>
  </div>
  <div style="height: 300px;position: relative" v-loading="loading" v-if="loading"></div>
</div>
</template>

<script>
import util from '@/utils/index.js'
export default {
  name: "study",
  data(){
    return {
      tabList:[
        {
          name:'全部',
          id:'-1',
        },
        {
          name:'学习中',
          id:'0',
        },
        {
          name:'已失效',
          id:'2',
        },
      ],
      curTabIndex:0,
      dataList:[],
      studyStatus:{},
      loading:true,
    }
  },
  created(){
    this.getList();
  },
  methods:{
    changeTab(index){
      this.curTabIndex = index;
      this.getList();
    },
    getList(){
      let params = {};
      if(this.tabList[this.curTabIndex].id != '-1'){
        params.study_status = this.tabList[this.curTabIndex].id;
      }
      this.loading = true;
      this.api.user.userSelfList(params).then((res)=>{
        this.loading = false;
        this.dataList = res.list;
        this.dataList.forEach((item)=>{
          item.exam_end_time = util.timeFormatter(
              new Date((+item.exam_end_time) * 1000),
              'yyyy-MM-dd hh:mm')
        })
        this.studyStatus = res.study_status;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    goStudy(data){
      this.$router.push({
        path:'/attestation/train',
        query:{
          id:data.category.id
        }
      })
    },
    goDetail(data){
      this.$router.push({
        path:'/attestation/detail',
        query:{
          id:data.category.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.study{
  .user-tit{
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: bold;
  }
  .study-list{
    margin-bottom: 30px;
    @include flex(row,flex-start,flex-start);
    flex-wrap: wrap;
    .study-list-item{
      margin-right: 20px;
      margin-top: 30px;
      padding: 20px;
      width: 234px;
      background: #FFFFFF;
      box-shadow: 0 0 20px 0 rgba(0,35,136,0.08);
      border-radius: 10px;
      .study-list-item-status{
        margin-top: 10px;
        @include btn(auto,23px,4px,12px,#1664FF);
        padding: 0 5px;
        display: inline-block;
      }
      .study-list-item-name{
        margin: 14px 0;
        font-size: 16px;
        font-weight: bold;
      }
      .study-list-item-desc{
        margin-bottom: 6px;
        font-size: 12px;
        color: #666666;
      }
      .study-list-item-btn{
        margin-top: 25px;
        @include btn(120px,38px,19px,14px,linear-gradient(222deg, #3A6CFF, #004FEB));
      }
    }
    .study-list-item:nth-child(4n){
      margin-right: 0;
    }
  }
}
</style>
